import { FormGroup, Label, Input } from "reactstrap";
import houseTypes from './houseTypes.json'; // Import the JSON file

function HouseTypeDropdown(props) {
  // Filter the house types based on the kitchen type
  let filteredHouseTypes;
  if (props.kitchenType === "Shared Ownership") {
    filteredHouseTypes = houseTypes.filter(houseType => houseType.so);
  } else if (props.kitchenType === "Housing Association") {
    filteredHouseTypes = houseTypes.filter(houseType => houseType.ha);
  } else {
    filteredHouseTypes = houseTypes;
  }
  // Filter the house types based on the kitchen type
  // const filteredHouseTypes = props.kitchenType === "Shared Ownership" 
  //   ? houseTypes.filter(houseType => houseType.so) 
  //   : houseTypes;

  return (
    <FormGroup>
      <Label for={props.name}>{props.title}</Label>
      <Input
        value={props.value ? props.value.name : ''} 
        onChange={(e) => {
          const selectedCombo = filteredHouseTypes.find(housetype => housetype.name === e.target.value);
          if (selectedCombo) {
            props.setValue(selectedCombo); 
          } else {
            props.setValue("");
          }
        }}
        required
        type="select"
        name={props.name}
        id={props.name}
        placeholder={props.title}
      >
        <option>Please Select</option>
        {filteredHouseTypes.map((housetype, index) => (
            <option key={index} value={housetype.name}>{housetype.name}</option>
        ))}
      </Input>
    </FormGroup>
  );
}

export default HouseTypeDropdown;