import { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import YesNoDropdown from "./YesNoDropdown";
import HouseTypeDropdown from "./HouseTypeDropdown";
import ComboDropdown from "./ComboDropdown";

//const apiKey = process.env.JTE_API_KEY;

function MainForm(props) {
  //Site and Plot Details
  const [orderNumber, setOrderNumber] = useState("");
  const [region, setRegion] = useState(props.loginInfo.region.trim());
  const [deliveryDate, setDeliveryDate] = useState("");
  const [site, setSite] = useState(props.loginInfo.site.trim());
  const [siteContactName, setSiteContactName] = useState(
    props.loginInfo.contactName
  );
  const [siteEmail, setSiteEmail] = useState(props.loginInfo.siteEmail.trim());
  const [siteContactNumber, setSiteContactNumber] = useState(
    props.loginInfo.siteNumber
  );
  const [plotNumber, setPlotNumber] = useState("");

  //Kitchen Details
  const [kitchenType, setKitchenType] = useState({
    name: "",
    "price": 0,
  });
  
  const [houseType, setHouseType] = useState({
    name: "",
    price: 0,
    so: false
  });
  
  const [combo, setCombo] = useState({
    tall: "",
    base: "",
    wall: "",
    name: "",
  });
  const [handing, setHanding] = useState("");

  //Upgrades
  const [upgradeWorktopSelection, setUpgradeWorktopSelection] = useState("");
  const [worktopColour, setWorktopColour] = useState("");

  const [fsDryer, setFsDryer] = useState("");
  const [fsWasher, setFsWasher] = useState("");
  const [fsWasherDryer, seFsWasherDryer] = useState("");

  //optionsets
  const [kitchenTypes, setKitchenTypes] = useState([]);
  const [worktopColours, setWorktopColours] = useState([]);
  const [houseTypes, setHouseTypes] = useState([]);

  //form validation
  const [formError, setFormError] = useState("");


  let appliances = [];

  let currentPrice = 0;
  let upgradePrice = 0;

  appliances.push(
    <YesNoDropdown
      value={fsDryer}
      name="fsDryer"
      setValue={setFsDryer}
      title="Freestanding Dryer"
    />
  );
  appliances.push(
    <YesNoDropdown
      value={fsWasher}
      name="fsWasher"
      setValue={setFsWasher}
      title="Freestanding Washer"
    />
  );
  appliances.push(
    <YesNoDropdown
      value={fsWasherDryer}
      name="fsWasherDryer"
      setValue={seFsWasherDryer}
      title="Freestanding Washer Dryer"
    />
  );

  const verifyForm = () => {
    setFormError("");

    const fields = [
      { field: orderNumber, errorMsg: "Please complete your order number." },
      { field: region, errorMsg: "Please complete your region.",},
      { field: deliveryDate, errorMsg: "Please complete your delivery date." },
      { field: site, errorMsg: "Please complete your site." },
      { field: siteContactName, errorMsg: "Please complete your site contact name." },
      { field: siteEmail, errorMsg: "Please complete your site email." },
      { field: siteContactNumber, errorMsg: "Please complete your site contact number." },
      { field: plotNumber, errorMsg: "Please complete your site plot number." },
      { field: siteEmail, errorMsg: "Please complete your site email." },
      { field: kitchenType, errorMsg: "Please complete your kitchen type." },
      { field: houseType.name, errorMsg: "Please complete your house type." },
      { field: combo.name, errorMsg: "Please complete your colour combo." },
      { field: handing, errorMsg: "Please complete your handing." },
      { field: upgradeWorktopSelection, errorMsg: "Please complete your upgrade worktop selection." },
      { field: fsDryer, errorMsg: "Please complete your freestanding dryer selection." },
      { field: fsWasher, errorMsg: "Please complete your freestanding washer selection." },
      { field: fsWasherDryer, errorMsg: "Please complete your freestanding washer dryer selection." },
    ];

    if (upgradeWorktopSelection === "Yes") {
      fields.push({ field: worktopColour, errorMsg: "Please complete your upgrade worktop colour." });
    }

    if (fields.some(({ field, errorMsg }) => !IsFieldValid(field, errorMsg))) {
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        ZHMPON: orderNumber,
        ZHMRGN: region,
        ZHMRDD: deliveryDate,
        ZHMSITE: site,
        ZHMSNAME: siteContactName,
        ZHMSEML: siteEmail,
        ZHMSTEL: siteContactNumber,
        ZHMPLOT: plotNumber,

        ZHMKTYP: kitchenType,
        ZHMHTYP: houseType.name,
        ZHMCOMB: combo.name,
        ZHMHAND: handing,

        ZHMWUPG: upgradeWorktopSelection,
        
        //ZHMWCOL: worktopColour,
        ZHMWCOL: worktopColour === "Please Select" ? "No" : worktopColour,


        ZHMPA1: fsDryer,
        ZHMPA2: fsWasher,
        ZHMPA3: fsWasherDryer,
        
        ZHMDTS: new Date(),
        ZHMPRI: (currentPrice + upgradePrice).toFixed(2),
        ZHMREF: " ",
        ZHMTYP: props.loginInfo.accountType,
        ZHMMAS: props.loginInfo.accountNumber,
        ZHMTITL: props.loginInfo.title,
        ZHMNAME: siteContactName,
        ZHMTEL: siteContactNumber,
        ZHMMAL: siteEmail,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://prod-25.uksouth.logic.azure.com:443/workflows/9c614e14846b498bb752af2882a78a03/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ySbT8hGFamyt4PPIGOmUGKs4S7KkQWzQ2XmfjExuNtA",
      requestOptions
    )
    .then((response) => {
        
      if (response.status === 409) {
        // Conflict - An order with the same housetype and plot number exists
        setFormError(
          "An order with the same housetype and plot number exists"
        );
        throw new Error("Conflict: The resource already exists.");
      }

      response.text();
    })
    .then((result) =>
      props.setComplete(true)
    )
    .catch((error) =>
      console.log("error", error)
    );

  };

  const ImageDisplay = ({ label, image, worktopColour, worktopUpgrade }) => (
    <Col>
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            position: "relative",
            display: "inline-block",
          }}
        >
          <img
            style={{
              width: "200px",
              height: "200px",
              display: "block",
              margin: "auto",
            }}
            src={"./" + ((label === "Worktops" && worktopColour && worktopUpgrade) ? worktopColour : (image || "Select Colour Combo")) + ".jpeg"}
            alt={image || "Select Colour Combo"}
          />
          <div
            style={{
              position: "absolute",
              top: "0",
              background: (label === "Worktops" && worktopColour && worktopUpgrade) ? "rgba(0, 126, 1, 1)" : "#08B0A0",
              color: "#f1f1f1",
              width: "100%",
              transition: "0.3s",
              textAlign: "center",
            }}
          >
            {(label === "Worktops" && worktopColour && worktopUpgrade) ? "Upgrade: " + label : label}
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "0",
              background: (label === "Worktops" && worktopColour && worktopUpgrade) ? "rgba(0, 126, 1, 1)" : "#08B0A0",
              color: (label === "Worktops" && worktopColour && worktopUpgrade) ? "#f1f1f1" : "#f1f1f1",
              width: "100%",
              transition: "0.3s",
              textAlign: "center",
            }}
          >
            {(label === "Worktops" && worktopColour && worktopUpgrade) ? worktopColour : (image || "Select Colour Combo")}
          </div>
        </div>
      </div>
    </Col>
  );

  function IsFieldValid(field, errorMsg) {
    var valid = hasUserSelectedValue(field);
    if (!valid) setFormError(errorMsg);
    return valid;
  }

  function isMonday(date) {
    const today = new Date();
    const dayOfWeek = today.getDay();
    let weeksToAdd = 3;

    if (dayOfWeek >= 4 || dayOfWeek === 0) {
      weeksToAdd = 4;
    }

    let nextNthMonday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + ((1 - dayOfWeek + 7) % 7)
    );

    nextNthMonday.setDate(nextNthMonday.getDate() + 7 * weeksToAdd);

    const isDateMonday = date.getDay() === 1;
    const isAfterNthMonday = date >= nextNthMonday;

    return isDateMonday && isAfterNthMonday;
  }

  function hasUserSelectedValue(option) {
    return !(
      option === "" ||
      option === undefined ||
      option === "Please Select" ||
      option === "Placeholder"
    );
  }

  function isUpgradeWorktop(option) {
    return !(
      option === "No" ||
      option === "Please Select" ||
      option === "Placeholder"
    );
  }

  const [warningMessageAppliance, setWarningMessageAppliance] = useState(null);

  useEffect(() => {
    
    if (upgradeWorktopSelection === 'No' || upgradeWorktopSelection === 'Please Select') {
      setWorktopColour('Please Select');
    }

    var getRequestOptions = {
      method: "GET",
      redirect: "follow",
    };


    setKitchenTypes(["Shared Ownership", "Private", "Housing Association"]);

    setWorktopColours([
      "Artic White",
      "Blizzard",
      "Crepuscule",
      "Moss",
      "Nut",
      "Snow Drift",
    ]);

    setHouseTypes(houseTypes);

    if (fsWasherDryer === "Yes" && (fsDryer === "Yes" || fsWasher === "Yes")) {
      setWarningMessageAppliance(
        "Warning: You have seleced a Washer/Dryer and either a Washer or Dryer. Please ensure you have selected the correct appliances."
      );
    } else {
      setWarningMessageAppliance(null);
    }

  }, [fsDryer, fsWasher, fsWasherDryer, upgradeWorktopSelection]);

  let displayPrice = `£${(currentPrice + upgradePrice).toFixed(2)}`;

  let submitForm = (
    <Container className="innerContainer">
      <Row>
        <Col md="12">
          <div>
            By clicking 'Place Order' you acknowledge that you are solely
            responsible for the accuracy of the information provided. JT Ellis
            cannot be held responsible for any errors or discrepancies. Please
            review your order carefully before finalizing. Thank you.
          </div>
          <br></br>
          <div className="text-danger">{formError}</div>
          <Button className="PlaceOrder" onClick={verifyForm}>
            Place Order
          </Button>{" "}
          {/* <span className="TotalPrice">{displayPrice}</span> */}
        </Col>
      </Row>
    </Container>
  );

  return (
    <Form>
      <Container className="innerContainer">
        <Row>
          <Col md="3" className="subTitleBlock">
            Site and Plot Details
          </Col>
          <Col md="9"></Col>
        </Row>
        <Row>
          <Col md="3">
            <FormGroup>
              <Label for="orderNumber">Purchase Order Number</Label>
              <Input
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
                maxLength={20}
                required
                type="text"
                name="orderNumber"
                id="orderNumber"
                placeholder="Purchase Order Number"
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label for="region">Region</Label>
              <Input
                value={region}
                required
                type="text"
                name="region"
                id="region"
                placeholder="Region"
                disabled
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label for="deliveryDate">W/C Delivery Date</Label>
              <DatePicker
                selected={deliveryDate}
                onChange={(date) => {
                  const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                  setDeliveryDate(utcDate);
                }}
                // onChange={(date) => setDeliveryDate(date)}
                filterDate={isMonday}
                dateFormat="dd/MM/yyyy"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <FormGroup>
              <Label for="site">Site</Label>
              <Input
                value={site}
                maxLength={50}
                required
                type="text"
                name="site"
                id="site"
                placeholder="Site"
                disabled
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label for="siteContactName">Site Contact Name</Label>
              <Input
                value={siteContactName}
                onChange={(e) => setSiteContactName(e.target.value)}
                maxLength={50}
                required
                type="text"
                name="siteContactName"
                id="siteContactName"
                placeholder="Site Contact Name"
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label for="orderNumber">Site Email</Label>
              <Input
                value={siteEmail}
                onChange={(e) => setSiteEmail(e.target.value)}
                maxLength={50}
                required
                type="email"
                name="siteEmail"
                id="siteEmail"
                placeholder="Site Email"
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label for="siteContactNumber">Site Contact Number</Label>
              <Input
                value={siteContactNumber}
                onChange={(e) => setSiteContactNumber(e.target.value)}
                maxLength={50}
                required
                type="text"
                name="siteContactNumber"
                id="siteContactNumber"
                placeholder="Site Contact Number"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <FormGroup>
              <Label for="plotNumber">Plot Number</Label>
              <Input
                value={plotNumber}
                onChange={(e) => setPlotNumber(e.target.value)}
                maxLength={5}
                required
                type="number"
                name="plotNumber"
                id="plotNumber"
                placeholder="Plot Number"
              />
            </FormGroup>
          </Col>
        </Row>
      </Container>

      <Container className="innerContainer">
        <Row>
          <Col md="3" className="subTitleBlock">
            Kitchen Details
          </Col>
          <Col md="9"></Col>
        </Row>

        <Row>
          <Col md="3">
            <FormGroup>
              <Label for="kitchenType">Kitchen Type</Label>
              <Input
                value={kitchenType}
                onChange={(e) => {
                  if (e.target.value !== kitchenType) {
                    setKitchenType(e.target.value);
                    setHouseType("Please Select");
                  }
                }}
                required
                type="select"
                name="kitchenType"
                id="kitchenType"
                placeholder="Kitchen Type"
              >
                <option>Please Select</option>
                {kitchenTypes.map((value) => (
                  <option key={value}>{value}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md="3">
            <HouseTypeDropdown
              value={houseType}
              name="houseType"
              setValue={setHouseType}
              title="House Type"
              kitchenType={kitchenType}
            />
          </Col>
          <Col md="3">
            <ComboDropdown
              value={combo}
              name="combo"
              setValue={setCombo}
              title="Colour Combo"
            />
          </Col>

          <Col md="3">
            <FormGroup>
              <Label for="handing">Handing AS/OPP</Label>
              <Input
                value={handing}
                onChange={(e) => setHanding(e.target.value)}
                required
                type="select"
                name="handing"
                id="handing"
                placeholder="Handing"
              >
                <option>Please Select</option>
                <option>AS</option>
                <option>OPP</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>

        
        


        <Row>
          
        <ImageDisplay label="Tall Units" image={combo && combo.tall} />
        <ImageDisplay label="Base Units" image={combo && combo.base} />
        <ImageDisplay label="Wall Units" image={combo && combo.wall} />
          
        </Row>
        <Row>
          
        <ImageDisplay label="Worktops" image={combo && combo.worktop} worktopColour={worktopColour} worktopUpgrade={isUpgradeWorktop(upgradeWorktopSelection)} />
        <ImageDisplay label="Splashbacks" image={combo && combo.splashback} />
          
        </Row>
      </Container>

      <Container className="innerContainer">
        <Row>
          <Col md="3" className="subTitleBlock">
            Upgrades
          </Col>
          <Col md="9"></Col>
        </Row>

        <Row>
          <Col md="9" className="subTitleNoteBlock"></Col>
        </Row>

        <Row>
          <Col md="5">
            <FormGroup>
              <Label for="appliances">
                Upgrade to Montelli Worksurfaces inc. Hot Tap
              </Label>
              <Input
                value={upgradeWorktopSelection}
                onChange={(e) => setUpgradeWorktopSelection(e.target.value)}
                required
                type="select"
                name="upgradeWorktop"
                id="upgradeWorktop"
                setWorktopColour={""}
              >
                <option>Please Select</option>
                <option>Yes</option>
                <option>No</option>
              </Input>
            </FormGroup>
          </Col>

          {upgradeWorktopSelection === "Yes" && (
            <Col md="3">
              <FormGroup>
                <Label for="worktopColour">Upgrade Worktop Colour</Label>
                <Input
                  value={worktopColour}
                  onChange={(e) => {
                    if (e.target.value !== worktopColour) {
                      setWorktopColour(e.target.value);
                    }
                  }}
                  required
                  type="select"
                  name="worktopColour"
                  id="worktopColour"
                  placeholder="Kitchen Type"
                >
                  <option>Please Select</option>
                  {worktopColours.map((value) => (
                    <option key={value}>{value}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          )}
          
        </Row>

        <Row>
          <Col md="9" className="subTitleNoteBlock"></Col>
        </Row>
        <Row>
          <Col md="3">{appliances[0]}</Col>
          <Col md="3">{appliances[1]}</Col>
          <Col md="3">{appliances[2]}</Col>
          {warningMessageAppliance && (
            <p className="warningMessage">{warningMessageAppliance}</p>
          )}
        </Row>
      </Container>

      {submitForm}
    </Form>
  );
}

export default MainForm;
