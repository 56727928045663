
import { Container, Row, Col} from "reactstrap";

function Thanks(props) {

    
  return (
    <Container className="innerContainer">
      <Row>
        <Col md="3" className="subTitleBlock">
          Thank You
        </Col>
        <Col md="9"></Col>
      </Row>
      <Row>
        <Col>
        Thank you for placing your kitchen order with JT Ellis. Should you require any further information please contact our team at Kitchens@jtellis.co.uk or 01484 514212.
        Your order will be processed by our team and you will receive an email confirmation shortly.
        </Col> 
      </Row>
    </Container>
  );
}

export default Thanks;
