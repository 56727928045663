import { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";

function Login(props) {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);

    let warningMessage = (<></>);

    if(props.loginInfo.status==="failed"){
      warningMessage=(<div className='warningMessage'>Login Failed. Please check your username and password.</div>)
    }
    
  return (
    <Container className="innerContainer">
      <Row>
        <Col md="3" className="subTitleBlock">
          Login
        </Col>
        <Col md="9"></Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <FormGroup>
                <Label for="loginEmail">Email</Label>
                <Input value={userName} onChange={(e) => setUserName(e.target.value)} required type="email" name="email" id="loginEmail" placeholder="Email" />
            </FormGroup>
            <FormGroup>
                <Label for="loginPassword">Password</Label>
                <Input value={password} onChange={(e) => setPassword(e.target.value)} required type="password" name="password" id="loginPassword" placeholder="Password" />
            </FormGroup>
            {warningMessage}
            <FormGroup>
                <Button onClick={() => attemptLogin(userName, password, props.setLoginInfo, setErrorMessage)}>Login</Button>
                {errorMessage && <div className="error">{errorMessage}</div>}
            </FormGroup>
          </Form>
        </Col> 
      </Row>
    </Container>
  );
}

async function attemptLogin(userName, password, setLoginInfo, setError) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var body = JSON.stringify({
    username: userName,
    password: password
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: body
  };
  
  try {
      const response = await fetch("https://prod-15.uksouth.logic.azure.com:443/workflows/fd77f23ec55142f1a6839943f5427c6a/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=q-D5kJNuRcXSgl32uX_mFHIlKl_0rvw5hBLLIlASkCo", requestOptions);
      
      if (!response.ok) {
          const errorResponse = await response.json(); 
          throw new Error(errorResponse.message); 
      }
      const result = await response.text();
      setLoginInfo(JSON.parse(result));
  } catch (error) {
      console.error('An error occurred:', error);
  }
}

export default Login;
