import { FormGroup, Label, Input } from "reactstrap";
import combos from './comboOptions.json';

function ComboDropdown(props) {
  return (
    <FormGroup>
      <Label for={props.name}>{props.title}</Label>
      <Input
        value={props.value ? props.value.name : ''} 
        onChange={(e) => {
          const selectedCombo = combos.find(combo => combo.name === e.target.value);
          if (selectedCombo) {
            props.setValue(selectedCombo); 
          } else {
            props.setValue("");
          }
        }}
        required
        type="select"
        name={props.name}
        id={props.name}
        placeholder={props.title}
      >
        <option>Please Select</option>
        {combos.map((combo, index) => (
            <option key={index} value={combo.name}>{combo.name}</option>
          ))}
      </Input>
    </FormGroup>
  );
}

export default ComboDropdown;
